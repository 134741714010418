import {range} from "lodash";
import dayjs from "dayjs";

export const YEARS = range(2000, dayjs().add(1, 'year').format("YYYY")).map(year => ({value: `${year}`, label: `${year}`}))
export const MONTHS = [
    {
        value: '01',
        label: 'January'
    },
    {
        value: '02',
        label: 'February'
    },
    {
        value: '03',
        label: 'March'
    },
    {
        value: '04',
        label: 'April'
    },
    {
        value: '05',
        label: 'May'
    },
    {
        value: '06',
        label: 'June'
    },
    {
        value: '07',
        label: 'July'
    },
    {
        value: '08',
        label: 'August'
    },
    {
        value: '09',
        label: 'September'
    },
    {
        value: '10',
        label: 'October'
    },
    {
        value: '11',
        label: 'November'
    },
    {
        value: '12',
        label: 'December'
    }
]
export const PER_PAGES = [
    {
        value: 15,
        label: '15'
    },
    {
        value: 25,
        label: '25'
    },
    {
        value: 50,
        label: '50'
    }
]
export const REPORT_TYPE_STATUS = {
    success: 'success',
    failed: 'failed',
    pending: 'pending',
    draft:'draft',
    review:'in-review',
    active:'active',
    registered:'registered',
    finished:'finished',
    error:'entered-in-error',
    rejected:'rejected',
}

export const EXCEL_EXPORT_TYPE = {
    Organization: 'Organization',
    Practitioner:'Practitioner',
    Patient:'Patient',
    MedicationRequest:'MedicationRequest',
    MedicationDispense:'MedicationDispense'
}
export const FIELDS={
    asyncSelect:'async-select',
    input:'input',
    datepicker:'datepicker',
    treeSelect:'tree-select',
    numberFormatInput:'number-format-input',
    textarea:'textarea',
    dropzone:'dropzone',
    phoneNumber:'phone-number',
    inputMask:'input-mask',
    select:'select',
}