import React from 'react';
import FormConsumer from "../../context/form/FormConsumer";
import Input from "./components/Input";
import Select from "./components/Select";
import AsyncSelect from "./components/AsyncSelect";
import MaskedInput from "./components/Masked-Input";
import PhoneNumber from "./components/PhoneNumber";
import Dropzone from "./components/Dropzone";
import Datepicker from "./components/Datepicker";
import {NumberFormatInput, Textarea, TreeSelect} from "./components";
import {FIELDS} from "../../constants";

const Field = ({type, ...rest}) => {
    return (
        <>
            {
                ((type) => {
                    switch (type) {
                        case FIELDS.input:
                            return <FormConsumer>{({attrs, getValueFromField}) => <Input {...rest} {...attrs}
                                                                                         getValueFromField={getValueFromField}/>}</FormConsumer>;
                        case FIELDS.select:
                            return <FormConsumer>{({attrs, getValueFromField}) => <Select {...rest} {...attrs}
                                                                                                getValueFromField={getValueFromField}/>}</FormConsumer>;
                        case FIELDS.asyncSelect:
                            return <FormConsumer>{({attrs, getValueFromField}) => <AsyncSelect {...rest} {...attrs}
                                                                                            getValueFromField={getValueFromField}/>}</FormConsumer>;
                        case FIELDS.inputMask:
                            return <FormConsumer>{({attrs, getValueFromField}) => <MaskedInput {...rest} {...attrs}
                                                                                               getValueFromField={getValueFromField}/>}</FormConsumer>;
                        case FIELDS.phoneNumber:
                            return <FormConsumer>{({attrs, getValueFromField}) => <PhoneNumber {...rest} {...attrs}
                                                                                            getValueFromField={getValueFromField}/>}</FormConsumer>;
                        case FIELDS.dropzone:
                            return <FormConsumer>{({attrs, getValueFromField}) => <Dropzone {...rest} {...attrs}
                                                                                                  getValueFromField={getValueFromField}/>}</FormConsumer>;
                        case FIELDS.datepicker:
                            return <FormConsumer>{({attrs, getValueFromField}) => <Datepicker {...rest} {...attrs}
                                                                                                    getValueFromField={getValueFromField}/>}</FormConsumer>;
                        case FIELDS.textarea:
                            return <FormConsumer>{({attrs, getValueFromField}) => <Textarea {...rest} {...attrs}
                                                                                              getValueFromField={getValueFromField}/>}</FormConsumer>;
                        case FIELDS.numberFormatInput:
                            return <FormConsumer>{({attrs, getValueFromField}) => <NumberFormatInput {...rest} {...attrs}
                                                                                            getValueFromField={getValueFromField}/>}</FormConsumer>;
                        case FIELDS.treeSelect:
                            return <FormConsumer>{({attrs, getValueFromField}) => <TreeSelect {...rest} {...attrs}
                                                                                                     getValueFromField={getValueFromField}/>}</FormConsumer>;
                        default:
                            return <FormConsumer>{({attrs, getValueFromField}) => <Input {...rest} {...attrs}
                                                                                         getValueFromField={getValueFromField}/>}</FormConsumer>
                    }

                })(type)
            }
        </>
    )
}

export default Field;
