import React from 'react';
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {ChevronLeft} from "react-feather";
import Content from "../../../components/content";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import {KEYS} from "../../../constants/keys";
import {usePostQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {Tab, Tabs} from "../../../components/tab";
import {FIELDS} from "../../../constants";
import Title from "../../../components/title";
import {ContentLoader} from "../../../components/loader";


const Patient066CreateContainer = ({id = null}) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const {
        mutate: addEncounterImpRequest, isLoading: isLoadingEncounterImp
    } = usePostQuery({listKeyId: KEYS.encounterImp})

    const addEncounterImp = ({data: formData}) => {
        const {...rest} = formData;
        addEncounterImpRequest({
            url: URLS.encounterImp,
            attributes: {
                ...rest,
                patient: {id: id},
            }
        }, {
            onSuccess: () => {
                navigate('/encounters/imp')
            }
        })
    }
    return (<>
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-3">
                    <Link className={'text-primary  font-bold inline-flex items-center'}
                          to={`/patient/view/${id}`}><ChevronLeft className={'mr-1'}/>{t("Назад к списку")}</Link>
                </div>
                <div className="col-span-12 mb-5">
                    <Title>{t("Поступление в стационар")}</Title>
                </div>
                <div className="col-span-12">
                    {isLoadingEncounterImp && <ContentLoader />}
                    <Content sm>
                        <Form classNames={'grid grid-cols-12 gap-x-6'}
                              formRequest={(data) => addEncounterImp(data)}
                              footer={<div className="col-span-4">
                                  <button type={'submit'}
                                          className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center mt-7'}>
                                      {t('Сохранить')}
                                  </button>
                              </div>}>
                            <Field type={FIELDS.asyncSelect}
                                   key={KEYS.bedType}
                                   url={URLS.bedType}
                                   params={{required: true}}
                                   classNames={'col-span-4'} name={`bed_type`}
                                   label={t('bed_type')}
                                   property={{optionLabel: 'code'}}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   key={KEYS.encounterPriority}
                                   url={URLS.encounterPriority}
                                   params={{required: true}}
                                   classNames={'col-span-4'} name={`priority`}
                                   label={t('priority')}
                            />
                            <Field type={FIELDS.treeSelect}
                                   key={KEYS.icd10}
                                   url={URLS.icd10}
                                   params={{required: true}}
                                   classNames={'col-span-4'} name={`admission_diagnosis.code.id`}
                                   label={t('Диагноз')}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   key={KEYS.conditionVerStatus}
                                   url={URLS.conditionVerStatus}
                                   params={{required: true}}
                                   classNames={'col-span-4'} name={`admission_diagnosis.verification_status`}
                                   label={t('Статус верификации')}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   key={KEYS.conditionClinical}
                                   url={URLS.conditionClinical}
                                   params={{required: true}}
                                   classNames={'col-span-4'} name={`admission_diagnosis.clinical_status`}
                                   label={t('Клинический статус')}
                            />
                            <Field
                                dateFormat={"yyyy-MM-dd"}
                                type={FIELDS.datepicker}
                                classNames={'col-span-4'}
                                name={'admission_date'}
                                params={{
                                    required: true,
                                }}
                                label={t('Дата приёма')}
                            />
                            <Field type={FIELDS.asyncSelect}
                                   key={KEYS.episodeOfCares}
                                   url={URLS.episodeOfCares}
                                   classNames={'col-span-4'} name={`episode_of_care`}
                                   label={t('episode_of_care')}
                                   property={{optionLabel: 'id', optionValue: 'id'}}
                                   dataKey={'payload.data'}
                            />
                        </Form>
                    </Content>
                </div>
            </div>
        </>
    );
}

export default Patient066CreateContainer;
