import React from 'react';
import Field from "../../containers/form/field";
import {FIELDS} from "../../constants";
import {URLS} from "../../constants/urls";
import {KEYS} from "../../constants/keys";
import {isEqual,get} from "lodash";
import Button from "../button";
import {Minus, Plus} from "react-feather";
import {useTranslation} from "react-i18next";
import {useFieldArray, useFormContext} from "react-hook-form";

const Index = () => {
    const {t} = useTranslation();
    const {control} = useFormContext();
    const {fields, append,  remove} = useFieldArray({
        control,
        name: 'diagnoses',
        keyName:'uuid'
    });
    return (
        <>
            {
                fields.map((field,index) => <div key={get(field,'uuid')}
                                      className="col-span-12 border py-2 px-4 rounded-xl mb-3">
                    <div className={'grid grid-cols-12 gap-x-6'}>
                        <div className="col-span-12">
                            <h2 className={'text-lg font-bold mb-3'}>{isEqual(index,0) ? t("Диагноз при поступлении") : t("Диагноз при выписке")}</h2>
                        </div>
                        <Field isDisabled={isEqual(index,0)} classNames={'col-span-4'} label={t("Рубрика")} type={FIELDS.asyncSelect}
                               params={{required: true}} url={URLS.diagnosesRubric}
                               keyId={KEYS.diagnosesRubric} name={`diagnoses[${index}].rubric`}/>
                        <Field isDisabled={isEqual(index,0)} type={FIELDS.treeSelect}
                               key={KEYS.icd10}
                               defaultValue={get(field,'code.id')}
                               url={get(field,'code.display') ? `${URLS.icd10}?name=${get(field,'code.display')}` : `${URLS.icd10}`}
                               params={{required: true}}
                               classNames={'col-span-4'} name={`diagnoses[${index}].code.id`}
                               label={t('Диагноз')}
                        />
                        <Field isDisabled={isEqual(index,0)} type={FIELDS.asyncSelect}
                               key={KEYS.conditionVerStatus}
                               url={URLS.conditionVerStatus}
                               params={{required: true}}
                               classNames={'col-span-4'} name={`diagnoses[${index}].verification_status`}
                               label={t('Статус верификации')}
                        />
                        {!isEqual(index,0) && <div className="col-span-12 flex justify-end">
                            <Button htmlType={'button'} onClick={() => remove(index)}
                                    classNames={'p-2 border-red-400'}><Minus color={'red'}/></Button>
                        </div>}
                    </div>
                </div>)
            }
            <div className={'col-span-12 mt-3 flex justify-end'}>
                <Button htmlType={'button'} onClick={() => append({rubric:"",code:null,verification_status:""})}
                        classNames={'p-2'}><Plus/></Button>
            </div>
        </>
    );
};

export default Index;
