import {useState} from 'react';
import {useGetAllQuery} from "../api";
import {get, includes} from "lodash";
import config from "../../config";
import {useSettingsStore} from "../../store";

const useTreeData = ({
                         keyId = "get-all",
                         url = "/",
                         limit = 100,
                         dataKey = null,
                         itemIsSelectable = false,
                         optionLabel = 'display'
                     }) => {
    const [loading, setLoading] = useState(false);
    const token = useSettingsStore(state => get(state, 'token', null))
    const lang = useSettingsStore(state => get(state, 'lang', null))


    let {data: options, isLoading} = useGetAllQuery({
        key: [keyId], url: url, params: {
            params: {
                limit,
                parent_id: includes(url, 'name') ? undefined : 'null'
            }
        },
    })
    options = get(options, 'data', []);


    const createNode = (item) => {
        const hasChildren = !get(item, 'is_last_level');
        const label = `${get(item, 'code', '')} ${get(item, optionLabel)}`;
        return {
            label,
            value: get(item, 'id'),
            selectable: get(item, 'selectable') || itemIsSelectable,
            children: hasChildren ? [] : null
        }
    }
    const getNodes = (items = dataKey ? get(options, dataKey, []) : options) => {
        console.log('items', items)
        return items.map(_item => createNode(_item))
    }
    const fetchNodes = async (activeNode) => {
        const res = await fetch(`${config.API_ROOT}${url}${includes(url, '?') ? '&' : '?'}parent_id=${get(activeNode, 'value')}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'PractitionerRole-Id': 8,
                'Accept-Language': lang
            }
        });

        return res.json()
    };

    const searchNodes = async (str) => {
        setLoading(true);
        const res = await fetch(`${config.API_ROOT}${url}?q=${str}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'PractitionerRole-Id': 8
            }
        });

        return res.json()
    };

    return {
        isLoading,
        getNodes,
        fetchNodes,
        searchNodes,
        searchLoading: loading,
        setLoading
    }
};

export default useTreeData;
