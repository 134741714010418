import React, {useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import { ChevronDown, ChevronLeft, ChevronUp, Edit2} from "react-feather";
import Content from "../../../components/content";
import {KEYS} from "../../../constants/keys";
import {useGetAllQuery, useGetOneQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {OverlayLoader} from "../../../components/loader";
import patientImg from "../../../assets/images/patient.png";
import {find, get, isEqual, filter, includes} from "lodash";
import phoneIcon from "../../../assets/icons/phone.svg";
import mobileIcon from "../../../assets/icons/mobile.svg";
import emailIcon from "../../../assets/icons/mail.svg";
import homeIcon from "../../../assets/icons/home.svg";
import serviceIcon from "../../../assets/icons/category.svg";
import chevronRightIcon from "../../../assets/icons/chevron-right-green.svg";
import {toast} from "react-toastify";
import {REPORT_TYPE_STATUS} from "../../../constants";
import clsx from "clsx";
import {AllowedAccess} from "react-permission-role";
import config from "../../../config";

const PatientContainer = ({id}) => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [collapsed, setCollapsed] = useState(false);
    const {data, isLoading} = useGetOneQuery({id: id, url: URLS.patients, key: [KEYS.patients, id]})
    const {data: episodeOfCareData, isLoading: isLoadingEpisodeOfCare} = useGetAllQuery({
        url: URLS.episodeOfCares, key: [KEYS.episodeOfCares, id], params: {
            params: {
                patient_id: id
            }
        }
    })

    if (isLoading || isLoadingEpisodeOfCare) {
        return <OverlayLoader/>
    }
    return (<>
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-1.5">
                    <Link className={'text-primary  font-bold inline-flex items-center'}
                          to={'/patient'}><ChevronLeft className={'mr-1'}/>{t("Назад к списку")}</Link>
                </div>
                <div className="col-span-12">
                    <Content sm>
                       <div className="flex mb-6 justify-between">
                           <Title>{t("Профиль пациента")}</Title>
                           <Link to={`/patient/update/${id}`}
                               className={'inline-flex items-center py-2 px-4 text-sm font-bold text-primary border border-primary rounded-lg'}>
                               <Edit2 size={18} className={'mr-3'}/>
                               {t("Редактировать данные")}
                           </Link>
                       </div>
                        <div className="grid grid-cols-12 gap-x-10">
                            <div className="col-span-4">
                                <div
                                    className={"p-6 rounded-xl shadow-xl drop-shadow-xl border-[3px] border-[rgba(0,0,0,0.1)]"}>
                                    <div className={'flex items-center'}>
                                        <img src={patientImg} alt=""/>
                                        <h2 className={'text-lg ml-5 font-bold'}>{get(data, 'data.payload.patient.last_name')} {get(data, 'data.payload.patient.first_name')} {get(data, 'data.payload.patient.middle_name')}</h2>
                                    </div>
                                    <h3 className={'text-lg font-bold mt-3'}>{t("Информация о пациенте")}:</h3>
                                    <ul>
                                        <li className={'flex items-center mt-3'}>
                                            <h4 className={'text-[#808080] font-semibold min-w-36'}>ПИНФЛ:</h4>
                                            <p className={'ml-3  font-semibold'}>{get(data, 'data.payload.patient.pin')}</p>
                                        </li>
                                        <li className={'flex items-center mt-3'}>
                                            <h4 className={'text-[#808080] font-semibold min-w-36'}>Пол:</h4>
                                            <p className={'ml-3  font-semibold'}>{get(data, 'data.payload.patient.gender.display')}</p>
                                        </li>
                                        <li className={'flex items-center mt-3'}>
                                            <h4 className={'text-[#808080] font-semibold min-w-36'}>Дата рождения:</h4>
                                            <p className={'ml-3  font-semibold'}>{get(data, 'data.payload.patient.birth_date')}</p>
                                        </li>
                                        <li className={'flex items-center mt-3'}>
                                            <h4 className={'text-[#808080] font-semibold min-w-36'}>Национальность:</h4>
                                            <p className={'ml-3  font-semibold'}>{get(data, 'data.payload.patient.nationality.display')}</p>
                                        </li>
                                        <li className={'flex items-center mt-3'}>
                                            <h4 className={'text-[#808080] font-semibold min-w-36'}>Гражданство:</h4>
                                            <p className={'ml-3  font-semibold'}>{get(data, 'data.payload.patient.citizenship.display')}</p>
                                        </li>
                                        <li className={'flex items-center mt-3'}>
                                            <h4 className={'text-[#808080] font-semibold min-w-36'}>Экстренный
                                                контакт:</h4>
                                            <p className={' ml-3 font-semibold'}>-</p>
                                        </li>
                                    </ul>
                                    <h3 className={'text-lg font-bold mt-6'}>{t("Контактная информация:")}:</h3>
                                    <ul>
                                        {get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 1)), 'value') &&
                                            <li className={'flex items-center mt-5'}>
                                                <img className={'flex-none'} src={phoneIcon} alt=""/>
                                                <span
                                                    className={'ml-4'}>+{get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 1)), 'value')}</span>
                                            </li>}
                                        {get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 1)), 'value') &&
                                            <li className={'flex items-center mt-4'}>
                                                <img className={'flex-none'} src={mobileIcon} alt=""/>
                                                <span
                                                    className={'ml-4'}>+{get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 1)), 'value')}</span>
                                            </li>}
                                        {get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 2)), 'value') &&
                                            <li className={'flex items-center mt-4'}>
                                                <img className={'flex-none'} src={emailIcon} alt=""/>
                                                <span
                                                    className={'ml-4'}>{get(find(get(data, 'data.payload.patient.telecoms', []), item => isEqual(get(item, 'system.id'), 2)), 'value')}</span>
                                            </li>}
                                        {get(find(get(data, 'data.payload.patient.addresses', []), item => isEqual(get(item, 'use.id'), 4)), 'line') &&
                                            <li className={'flex items-center mt-4'}>
                                                <img className={'flex-none'} src={homeIcon} alt=""/>
                                                <span
                                                    className={'ml-4'}>{get(find(get(data, 'data.payload.patient.addresses', []), item => isEqual(get(item, 'use.id'), 4)), 'line')}</span>
                                            </li>}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-span-8">
                                <div
                                    className={"p-6 rounded-xl shadow-xl drop-shadow-xl border-[3px] border-[rgba(0,0,0,0.1)]"}>
                                    <h4 className={'text-lg font-bold mb-6'}>{t("Информация о здоровье")}:</h4>
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-3">
                                            <div className={'mb-4'}>
                                                <h4 className={'mb-1.5 text-[#808080] font-semibold'}>Группа
                                                    инвалидности:</h4>
                                                <p className={'font-semibold'}>-</p>
                                            </div>
                                            <div className={'mb-4'}>
                                                <h4 className={'mb-1.5 text-[#808080] font-semibold'}>Группа крови:</h4>
                                                <p className={' font-semibold'}>-</p>
                                            </div>
                                        </div>
                                        <div className="col-span-3">
                                            <div className={'mb-4'}>
                                                <h4 className={'mb-1.5 text-[#808080] font-semibold'}>Аллергические
                                                    реакции:</h4>
                                                <p className={' font-semibold'}>-</p>
                                            </div>
                                            <div className={'mb-4'}>
                                                <h4 className={'mb-1.5 text-[#808080] font-semibold'}>Категория
                                                    льготности:</h4>
                                                <p className={' font-semibold'}>-</p>
                                            </div>
                                        </div>

                                        <div className="col-span-6">
                                            <div className={'mb-4'}>
                                                <h4 className={'mb-1.5 text-[#808080] font-semibold'}>Д учёт:</h4>
                                                {filter(get(episodeOfCareData, 'data.payload.data', []), (_item) => !includes(['cancelled', 'entered-in-error'], get(_item, 'status.code')))?.length ?
                                                    <ul className={'shadow p-3'}>
                                                        {
                                                            filter(get(episodeOfCareData, 'data.payload.data', []), (_item) => !includes(['cancelled', 'entered-in-error'], get(_item, 'status.code'))).map((item, index) =>
                                                                <li className={clsx('mb-1.5  block', {'!hidden': index > 4 && !collapsed})}
                                                                    key={get(item, 'id')}>
                                                                    {index + 1}. <strong>{get(item, 'main_diagnosis.code.code')}</strong> {get(item, 'main_diagnosis.code.display')}
                                                                </li>)
                                                        }
                                                        {filter(get(episodeOfCareData, 'data.payload.data', []), (_item) => !includes(['cancelled', 'entered-in-error'], get(_item, 'status.code')))?.length > 4 && <>{!collapsed &&
                                                            <ChevronDown className={'mx-auto cursor-pointer'}
                                                                         color={'#808080'} size={32}
                                                                         onClick={() => setCollapsed(true)}/>}
                                                            {collapsed &&
                                                                <ChevronUp className={'mx-auto cursor-pointer'}
                                                                           color={'#808080'} size={32}
                                                                           onClick={() => setCollapsed(false)}/>}</>}
                                                    </ul> : '-'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={"p-6 rounded-xl shadow-xl drop-shadow-xl border-[3px] border-[rgba(0,0,0,0.1)] mt-6"}>
                                    <div className="grid grid-cols-12 gap-x-6">
                                        <div onClick={() => navigate(`/patient/encounter/${id}`)}
                                             className="col-span-4  border-2 border-[#E5E5E5] rounded py-2 px-3 flex items-center cursor-pointer mb-6 justify-between pr-6 hover:shadow-xl">
                                            <img src={serviceIcon} alt="service"/>
                                            <span className={'ml-3 text-center'}> Амбулаторный прием
                                            025 форма</span>
                                            <img className={'ml-4'} src={chevronRightIcon} alt="chevronRightIcon"/>
                                        </div>
                                        <AllowedAccess permissions={[config.PERMISSIONS.episode_of_care_create]}>
                                            <div onClick={() => {
                                                if (get(episodeOfCareData, 'data.payload.data', [])?.length) {
                                                    navigate(`/patient/episode-of-cares/${id}`);
                                                } else {
                                                    navigate(`/patient/episode-of-cares/${id}?openModal=1`);
                                                }

                                            }
                                            }
                                                 className="col-span-4  border-2 border-[#E5E5E5] rounded py-2 px-3 flex items-center cursor-pointer mb-6 justify-between pr-6 hover:shadow-xl">
                                                <img src={serviceIcon} alt="service"/>
                                                <span className={'ml-3'}> {t('"Д" учета')} </span>
                                                <img className={'ml-4'} src={chevronRightIcon} alt="chevronRightIcon"/>
                                            </div>
                                        </AllowedAccess>
                                        <AllowedAccess permissions={[config.PERMISSIONS.medication_request_create]}>
                                            <div onClick={() => {
                                                if (filter(get(episodeOfCareData, 'data.payload.data', []), (item) => includes([REPORT_TYPE_STATUS.active, REPORT_TYPE_STATUS.registered], get(item, 'status.code')))?.length) {
                                                    navigate(`/patient/medication-request/${id}`)
                                                } else {
                                                    toast.warn(t('У этого пациента нет активного Д учёт.Пожалуйста, создайте Д учёт'))
                                                }
                                            }}
                                                 className="col-span-4  border-2 border-[#E5E5E5] rounded py-2 px-3 flex items-center cursor-pointer mb-6 justify-between pr-6 hover:shadow-xl">
                                                <img src={serviceIcon} alt="service"/>
                                                <span className={'ml-3'}>Рецепт</span>
                                                <img className={'ml-4'} src={chevronRightIcon} alt="chevronRightIcon"/>
                                            </div>
                                        </AllowedAccess>
                                        <AllowedAccess permissions={[config.PERMISSIONS.medication_request_create]}>
                                            <div onClick={() => {
                                                navigate(`/patient/066/${id}?tab=encounterIMP`)
                                            }}
                                                 className="col-span-4  border-2 border-[#E5E5E5] rounded py-2 px-3 flex items-center cursor-pointer mb-6 justify-between pr-6 hover:shadow-xl">
                                                <img src={serviceIcon} alt="service"/>
                                                <span className={'ml-3'}>{t("Форма №066")}</span>
                                                <img className={'ml-4'} src={chevronRightIcon} alt="chevronRightIcon"/>
                                            </div>
                                        </AllowedAccess>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>
                </div>
            </div>
        </>
    );
}

export default PatientContainer;