import React from 'react';
import Field from "../../containers/form/field";
import {FIELDS} from "../../constants";
import {useTranslation} from "react-i18next";
import {get, isEqual} from "lodash"
import {useFieldArray, useFormContext} from "react-hook-form";
import Button from "../button";
import {Minus, Plus} from "react-feather";

const Index = () => {
    const {t} = useTranslation();
    const {control} = useFormContext();
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'newborns',
        keyName: 'uuid'
    });
    return (
        <>
            {
                fields.map((field, index) => <div key={get(field, 'uuid')}
                                                  className="col-span-12 border py-2 px-4 rounded-xl mb-3">
                    <div className={'grid grid-cols-12 gap-x-6 pt-3'}>

                        <Field  type={FIELDS.inputMask}
                               params={{required: true, pattern: {value: /^[0-9]{14}$/, message: 'Invalid value'}}}
                               classNames={'col-span-4'} name={`newborns[${index}].mother_pin`}
                               label={t('Mother pin')}
                               property={{
                                   placeholder: t('ПИНФЛ'),
                                   mask: '99999999999999',
                                   maskChar: '_',
                               }}
                        />
                        <Field type={FIELDS.input}
                               params={{required: true,valueAsNumber:true}}
                               classNames={'col-span-4'} name={`newborns[${index}].weight`}
                               label={t('Вес')}
                        />
                        <Field type={FIELDS.input}
                               params={{required: true,valueAsNumber:true}}
                               classNames={'col-span-4'} name={`newborns[${index}].height`}
                               label={t('Рост')}
                        />
                        <Field type={FIELDS.datepicker}
                               params={{required: true}}
                               classNames={'col-span-4'} name={`newborns[${index}].birth_date`}
                               label={t('Birthdate')}
                        />
                        {!isEqual(index, 0) && <div className="col-span-12 flex justify-end">
                            <Button htmlType={'button'} onClick={() => remove(index)}
                                    classNames={'p-2 border-red-400'}><Minus color={'red'}/></Button>
                        </div>}
                    </div>
                </div>)
            }
            <div className={'col-span-12 mt-3 flex justify-end'}>
                <Button htmlType={'button'} onClick={() => append({})}
                        classNames={'p-2'}><Plus/></Button>
            </div>
        </>
    );
};

export default Index;
