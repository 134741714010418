import React, {useState} from 'react';
import Content from "../../../components/content";
import Title from "../../../components/title";
import {NavLink} from "react-router-dom";
import {useGetAllQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {get} from "lodash";
import {KEYS} from "../../../constants/keys";
import {useTranslation} from "react-i18next";
import downloadIcon from "../../../assets/icons/download.svg"
import YearMonthPicker from "../../../components/year-month-picker";
import dayjs from "dayjs";
import GridView from "../../../containers/grid-view";
import {NumericFormat} from "react-number-format";
import Button from "../../../components/button";
import {EXCEL_EXPORT_TYPE, FIELDS} from "../../../constants";
import {toast} from "react-toastify";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import Badge from "../../../components/badge";

const ReportContainer = () => {
    const {t} = useTranslation()
    const [filter, setFilter] = useState({})
    const [month, setMonth] = useState(null);
    const [regionId, setRegionId] = useState(null)
    const [cityId, setCityId] = useState(null)
    const [year, setYear] = useState(null);
    const [exportLoading, setExportLoading] = useState(false);

    const {
        refetch, isLoading: isLoadingExportExcel
    } = useGetAllQuery({
        listKeyId: KEYS.exportExcel,
        enabled: false,
        url: `${URLS.exportExcel}/${EXCEL_EXPORT_TYPE.MedicationDispense}`,
        cb: {
            success: (res) => {
                toast.success(get(res, 'message'))
                setExportLoading(false)
            },
            fail: () => {
                setExportLoading(false)
            }
        }
    })
    return (
        <>
            <div className="flex justify-between items-center">
                <Title className={'mb-5'}>{t("Reports")}</Title>
                <Button loading={exportLoading} onClick={() => {
                    setExportLoading(true);
                    refetch();
                }} icon={<img className={'mr-2.5'} src={downloadIcon} alt={'export'}/>} classNames={'!pr-5 !text-sm '}>
                    {t("Export")}
                </Button>
            </div>
            <Content classNames={'bg-white rounded-2xl !min-h-[calc(100vh-300px)]'}>
                <div className="grid grid-cols-12 gap-x-8">
                    <div className="col-span-2">
                        <div
                            className={"p-6 rounded-xl shadow-xl drop-shadow-xl border-[3px] border-[rgba(0,0,0,0.1)]"}>
                            <NavLink
                                className={'block border border-[#47748A] rounded-lg text-center p-2.5 mb-3 font-semibold  '}
                                to={'#'}>
                                {t("Бюджета по реимбурсации")}
                            </NavLink>
                            <NavLink
                                className={'block border border-[#47748A] rounded-lg text-center p-2.5 mb-3 font-semibold  '}
                                to={'#'}>
                                {t("Организации")}
                            </NavLink>
                            <NavLink
                                className={'block border border-[#E5E5E5] rounded-lg text-center p-2.5 mb-3 font-semibold bg-[#47748A] text-white'}
                                to={'/pharmacy/report'}>
                                {t("Отчёт по аптека")}
                            </NavLink>
                        </div>
                    </div>
                    <div className="col-span-10">
                        <div className="mb-3">
                            <YearMonthPicker hasBorder getMonth={setMonth} getYear={setYear}/>
                        </div>
                        <div className={'z-0 relative'}>
                            <GridView
                                isContentLoader
                                filters={<div className={' pb-4  '}>
                                    <Form resetFields={{
                                        name: '',
                                        organization_id: null,
                                        patient_id: null,
                                        status_id: null,
                                        requester_id: null,
                                        dispenser_id: null,
                                        rx_code: '',
                                        date_from: null,
                                        date_to: null,
                                        state_id: null,
                                        district_id: null,
                                        city_id: null
                                    }}
                                          defaultValues={{...filter}}
                                          classNames={'grid grid-cols-12 gap-x-4 items-start'}
                                          formRequest={({data}) => {
                                              const {
                                                  name,
                                                  status_id,
                                                  patient_id,
                                                  requester_id,
                                                  dispenser_id,
                                                  rx_code,
                                                  date_from,
                                                  date_to,
                                                  state_id,
                                                  district_id,
                                                  city_id
                                              } = data;
                                              setFilter({
                                                  name,
                                                  status_id: get(status_id, 'id'),
                                                  patient_id: get(patient_id, 'id'),
                                                  requester_id: get(requester_id, 'id'),
                                                  dispenser_id: get(dispenser_id, 'id'),
                                                  rx_code: rx_code,
                                                  date_from: date_from,
                                                  date_to: date_to,
                                                  state_id: get(state_id, 'id'),
                                                  district_id: get(district_id, 'id'),
                                                  city_id: get(city_id, 'id'),
                                              })
                                          }
                                          }>
                                        <Field classNames={'col-span-3'} type={FIELDS.input} name={'name'}
                                               placeholder={t('Ф.И.О')}/>
                                        <Field classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               property={{optionLabel: 'first_name'}} name={'patient_id'}
                                               placeholder={t('Пациент')} url={URLS.patients}
                                               keyId={KEYS.patients}/>
                                        <Field classNames={'col-span-3'} type={FIELDS.asyncSelect} name={'status_id'}
                                               placeholder={t('Status')} url={URLS.medicationrequestStatusV1}
                                               keyId={KEYS.medicationrequestStatusV1}/>
                                        <Field classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'organization_id'}
                                               placeholder={t('Organization')} url={URLS.organizations}
                                               keyId={KEYS.organizations}/>
                                        <Field classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'requester_id'}
                                               placeholder={t('Requester')} url={URLS.practitionerRoleList}
                                               keyId={KEYS.practitionerRoleList}/>
                                        <Field classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'dispenser_id'}
                                               placeholder={t('Dispenser')} url={URLS.practitionerRoleList}
                                               keyId={KEYS.practitionerRoleList}/>

                                        <Field classNames={'col-span-3'} type={FIELDS.input} name={'rx_code'}
                                               placeholder={t('Номер рецепта')}/>
                                        <Field property={{onChange: (val) => setRegionId(get(val, 'id'))}}
                                               classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'state_id'}
                                               placeholder={t('State')}
                                               url={`${URLS.organizationTerritory}?limit=100&level=1`}
                                               keyId={KEYS.organizationTerritory}/>
                                        <Field property={{onChange: (val) => setCityId(get(val, 'id'))}}
                                               enabled={!!(regionId)}
                                               classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'city_id'}
                                               placeholder={t('City')}
                                               url={`${URLS.organizationTerritory}?limit=100&level=2&parent_id=${regionId}`}
                                               keyId={KEYS.organizationTerritory}/>
                                        <Field enabled={!!(cityId)} classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                               dataKey={'payload.data'}
                                               name={'district_id'}
                                               placeholder={t('District')}
                                               url={`${URLS.organizationTerritory}?limit=100&level=3&parent_id=${cityId}`}
                                               keyId={KEYS.organizationTerritory}/>
                                        <div className={'col-span-6 mt-1.5'}>
                                            <div className="flex items-center">
                                                <button type={'submit'}
                                                        className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  h-12'}>
                                                    {t('Фильтр')}
                                                </button>
                                                <button onClick={() => setFilter({})} type={'reset'}
                                                        className={'ml-3 text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg inline-block   font-bold text-center h-12'}>
                                                    {t('Очистить фильтр')}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>}
                                bordered
                                dataKey={'data.payload.data'}
                                metaDataKey={'data.payload.meta'}
                                params={{
                                    ...filter,
                                    date_from: month && year ? `${year}-${month}-01` : undefined,
                                    date_to: month && year ? `${year}-${month}-31` : undefined,
                                }}
                                listKey={KEYS.medicationDispense}
                                url={URLS.medicationDispense}
                                columns={[
                                    {
                                        title: t('Номер рецепта'),
                                        key: 'medication_request.rx_code',
                                    },
                                    {
                                        title: t('Brand'),
                                        key: 'brand.display',
                                    },
                                    {
                                        title: t('Наименование лекарства'),
                                        key: 'medication_request.medication.vproduct.display',
                                    },
                                    {
                                        title: t('Количество выписанного ЛС'),
                                        key: 'medication_request.instructions[0].quantity',
                                    },
                                    {
                                        title: t('Срок действия'),
                                        key: 'medication_request.valid_until',
                                        render: ({value}) => dayjs(value).format('DD.MM.YYYY HH:mm')
                                    },
                                    {
                                        title: t('Дата выдачи'),
                                        key: 'created_at',
                                        render: ({value}) => dayjs(value).format('DD.MM.YYYY HH:mm')
                                    },
                                    {
                                        title: t('Стоиност рецепта'),
                                        key: 'order_details.total_price',
                                        render: ({value}) => <NumericFormat displayType={'text'} value={value}
                                                                            thousandSeparator={" "}/>
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    );
};

export default ReportContainer;
