import React, {useState} from 'react';
import Field from "../../containers/form/field";
import {FIELDS} from "../../constants";
import {URLS} from "../../constants/urls";
import {KEYS} from "../../constants/keys";
import {useTranslation} from "react-i18next";
import {get, isEqual} from "lodash"
import {useFieldArray, useFormContext} from "react-hook-form";
import Button from "../button";
import {Minus, Plus} from "react-feather";

const Index = () => {
    const {t} = useTranslation();
    const [answers, setAnswers] = useState([])
    const {control} = useFormContext();
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'observations',
        keyName: 'uuid'
    });
    return (
        <>
            {
                fields.map((field, index) => <div key={get(field, 'uuid')}
                                                  className="col-span-12 border py-2 px-4 rounded-xl mb-3">
                    <div className={'grid grid-cols-12 gap-x-6 pt-3'}>
                        <Field dataKey={'payload.data'} type={FIELDS.asyncSelect}
                               key={KEYS.observationCategory}
                               url={URLS.observationCategory}
                               params={{required: true}}
                               classNames={'col-span-4'} name={`observations[${index}].category`}
                               label={t('Category')}
                        />
                        <Field type={FIELDS.asyncSelect}
                               key={KEYS.concepts}
                               property={{
                                   optionLabel: 'name',
                                   optionValue: 'id',
                                   onChange: (val) => setAnswers(get(val, 'answers', []))
                               }}
                               dataKey={'payload.data'}
                               url={`${URLS.concepts}?class_code=test`}
                               params={{required: true}}
                               classNames={'col-span-4'} name={`observations[${index}].concept`}
                               label={t('Analyze type')}
                               searchKeyName={null}
                        />
                        <Field type={FIELDS.select}
                               classNames={'col-span-4'} name={`observations[${index}].value_code`}
                               label={t('Analyze result')}
                               options={answers}
                        />
                        <Field type={FIELDS.datepicker}
                               params={{required: true}}
                               classNames={'col-span-4'} name={`observations[${index}].obs_date`}
                               label={t('obs_date')}
                        />
                        {!isEqual(index, 0) && <div className="col-span-12 flex justify-end">
                            <Button htmlType={'button'} onClick={() => remove(index)}
                                    classNames={'p-2 border-red-400'}><Minus color={'red'}/></Button>
                        </div>}
                    </div>
                </div>)
            }
            <div className={'col-span-12 mt-3 flex justify-end'}>
                <Button htmlType={'button'} onClick={() => append({})}
                        classNames={'p-2'}><Plus/></Button>
            </div>
        </>
    );
};

export default Index;
