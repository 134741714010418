import React from 'react';
import Field from "../../containers/form/field";
import {FIELDS} from "../../constants";
import {URLS} from "../../constants/urls";
import {KEYS} from "../../constants/keys";
import {isEqual, get} from "lodash";
import Button from "../button";
import {Minus, Plus} from "react-feather";
import {useTranslation} from "react-i18next";
import {useFieldArray, useFormContext} from "react-hook-form";

const Index = () => {
    const {t} = useTranslation();
    const {control} = useFormContext();
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'procedures',
        keyName: 'uuid'
    });
    return (
        <>
            {
                fields.map((field, index) => <div key={get(field, 'uuid')}
                                                  className="col-span-12 border py-2 px-4 rounded-xl mb-3">
                    <div className={'grid grid-cols-12 gap-x-6'}>
                        <div className="col-span-12">
                            <h2 className={'text-lg font-bold mb-3'}>{isEqual(index, 0) ? t("Основная хирургические операции") : t('Хирургические операции')}</h2>
                        </div>
                        <Field type={FIELDS.treeSelect}
                               key={KEYS.concepts}
                               property={{optionLabel: 'name', optionValue: 'id'}}
                               dataKey={'payload.data'}
                               itemIsSelectable
                               defaultValue={get(field, 'concept.id')}
                               url={get(field, 'concept.display') ? `${URLS.concepts}?name=${get(field, 'concept.display')}&class_code=procedure&is_set=true` : `${URLS.concepts}?class_code=procedure&is_set=true`}
                               params={{required: true}}
                               classNames={'col-span-6'} name={`procedures[${index}].concept.id`}
                               label={t('Concept')}
                        />

                        <Field type={FIELDS.datepicker}
                               params={{required: true}}
                               property={{showTimePicker: true}}
                               dateFormat={"dd-MM-yyyy HH:mm"}
                               classNames={'col-span-6'} name={`procedures[${index}].proc_date`}
                               label={t('proc_date')}
                        />
                        {!isEqual(index, 0) && <div className="col-span-12 flex justify-end">
                            <Button htmlType={'button'} onClick={() => remove(index)}
                                    classNames={'p-2 border-red-400'}><Minus color={'red'}/></Button>
                        </div>}
                    </div>
                </div>)
            }
            <div className={'col-span-12 mt-3 flex justify-end'}>
                <Button htmlType={'button'} onClick={() => append({})}
                        classNames={'p-2'}><Plus/></Button>
            </div>
        </>
    );
};

export default Index;
