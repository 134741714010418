import React,{useState} from 'react';
import Title from "../../../components/title";
import GridView from "../../../containers/grid-view";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import {get} from "lodash"
import {useNavigate} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {Plus} from "react-feather";
import {useGetAllQuery} from "../../../hooks/api";
import {EXCEL_EXPORT_TYPE, FIELDS} from "../../../constants";
import downloadIcon from "../../../assets/icons/download.svg";
import {toast} from "react-toastify";
import Button from "../../../components/button";
import {AllowedAccess} from "react-permission-role";
import config from "../../../config";
import  {hasPermission} from "../../../services/auth/HasAccess";
import {useStore} from "../../../store";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";


const PatientsContainer = () => {
    const user = useStore(state => get(state, "user", null))
    const navigate = useNavigate();
    const [loadingExport, setLoadingExport] = useState(false)
    const [regionId, setRegionId] = useState(null)
    const [cityId, setCityId] = useState(null)
    const [filter, setFilter] = useState({sort:'-updated_at'})
    const {t} = useTranslation();

    const {
        refetch
    } = useGetAllQuery({
        listKeyId: KEYS.exportExcel,
        enabled: false,
        url: `${URLS.exportExcel}/${EXCEL_EXPORT_TYPE.Patient}`,
        cb: {
            success: (res) => {
                toast.success(get(res, 'message'))
                setLoadingExport(false)
            },
            fail: () => {
                setLoadingExport(false)
            }
        }
    })


    const columns = [
        {
            title: t('Ф.И.О'),
            key: 'parent.display',
            render: ({row}) => `${get(row, 'last_name')} ${get(row, 'first_name')} ${get(row, 'middle_name')}`
        },
        {
            title: t('ПИНФЛ'),
            key: 'pin',
        },
        {
            title: t('Дата рождения'),
            key: 'birth_date',
        },
        {
            title: t('Телефон'),
            key: 'phone',
            render: ({row}) => <span> {row?.telecoms[0]?.value} </span>
        },
    ]

    const sortOptions = [
        { label: t('Дата обновления (по возрастанию)'), value: "updated_at" },
        { label: t('Дата обновления (по убыванию)'), value: "-updated_at" },
        // { label: t('ID (по возрастанию)'), value: "id" },
        // { label: t('ID (по убыванию)'), value: "-id" },
        { label: t('Имя (по возрастанию)'), value: "first_name" },
        { label: t('Имя (по убыванию)'), value: "-first_name" },
        { label: t('Фамилия (по возрастанию)'), value: "last_name" },
        { label: t('Фамилия (по убыванию)'), value: "-last_name" }
    ];

    return (
        <div>
            <div className="grid grid-cols-12 items-center">
                <div className="col-span-6">
                    <Title>{t("Пациенты")}</Title>
                </div>
                <div className="col-span-6 flex items-center justify-end">
                    <AllowedAccess permissions={[config.PERMISSIONS.patient_create]}><Button onClick={() => navigate('/patient/create')} classNames={'!bg-primary mr-4 text-white  pr-5'}
                            icon={<Plus className={'mr-1.5'}/>}>
                        {t('Добавить пациента')}
                    </Button>
                    </AllowedAccess>
                    <AllowedAccess permissions={[config.PERMISSIONS.patient_export]}>
                    <Button classNames={'!text-sm !pr-5'} loading={loadingExport} onClick={() => {
                        setLoadingExport(true);
                        refetch();
                    }} icon={<img className={'mr-2.5'} src={downloadIcon} alt={'export'}/>}>{t("Export")}</Button>
                    </AllowedAccess>
                </div>
                <div className="col-span-12 mt-6">
                    <GridView
                        isContentLoader
                        filters={<div className={' py-4 px-2 '}>
                            <Form resetFields={{
                                name:'',
                                pin:'',
                                sort:'-updated_at',
                                state_id: null,
                                district_id: null,
                                city_id: null
                            }}
                                  defaultValues={{...filter}}
                                  classNames={'grid grid-cols-12 gap-x-4 items-start'}
                                  formRequest={({data}) => {
                                      const {
                                          name,
                                          pin,
                                          sort,
                                          state_id,
                                          district_id,
                                          city_id
                                      } = data;
                                      setFilter({
                                          name,
                                          pin,
                                          sort:get(sort,'value'),
                                          state_id: get(state_id, 'id'),
                                          district_id: get(district_id, 'id'),
                                          city_id: get(city_id, 'id'),
                                      })
                                  }
                                  }>
                                <Field classNames={'col-span-3'} type={FIELDS.input} name={'name'}
                                       placeholder={t('Ф.И.О')}/>
                                <Field type={FIELDS.inputMask}
                                       params={{pattern: {value: /^[0-9]{14}$/, message: 'Invalid value'}}}
                                       classNames={'col-span-3'}
                                       name={'pin'}
                                       property={{
                                           placeholder: t('ПИНФЛ'),
                                           mask: '99999999999999',
                                           maskChar: '_',
                                       }}
                                />
                                <Field type={FIELDS.select}
                                       classNames={'col-span-3'}
                                       name={'sort'}
                                       placeholder={t('Sort')}
                                       options={sortOptions}
                                       property={{optionValue:'value',optionLabel:'label'}}
                                />

                                <Field property={{onChange: (val) => setRegionId(get(val, 'id'))}}
                                       classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                       dataKey={'payload.data'}
                                       name={'state_id'}
                                       placeholder={t('State')}
                                       url={`${URLS.organizationTerritory}?limit=100&level=1`}
                                       keyId={KEYS.organizationTerritory}/>
                                <Field property={{onChange: (val) => setCityId(get(val, 'id'))}}
                                       enabled={!!(regionId)}
                                       classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                       dataKey={'payload.data'}
                                       name={'city_id'}
                                       placeholder={t('City')}
                                       url={`${URLS.organizationTerritory}?limit=100&level=2&parent_id=${regionId}`}
                                       keyId={KEYS.organizationTerritory}/>
                                <Field enabled={!!(cityId)} classNames={'col-span-3'} type={FIELDS.asyncSelect}
                                       dataKey={'payload.data'}
                                       name={'district_id'}
                                       placeholder={t('District')}
                                       url={`${URLS.organizationTerritory}?limit=100&level=3&parent_id=${cityId}`}
                                       keyId={KEYS.organizationTerritory}/>
                                <div className={'col-span-6 mt-1.5'}>
                                    <div className="flex items-center">
                                        <button type={'submit'}
                                                className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  h-12'}>
                                            {t('Фильтр')}
                                        </button>
                                        <button onClick={() => setFilter({})} type={'reset'}
                                                className={'ml-3 text-[#7A7A7A] border-2 border-[#7A7A7A] py-3 px-6 rounded-lg inline-block   font-bold text-center h-12'}>
                                            {t('Очистить фильтр')}
                                        </button>
                                    </div>
                                </div>
                            </Form>

                        </div>}
                        updateUrl={'/patient/update'}
                        viewUrl={'/patient/view'}
                        dataKey={'data.payload.data'}
                        metaDataKey={'data.payload.meta'}
                        params={{
                            ...filter
                        }}
                        hasActionColumn
                        listKey={KEYS.patients}
                        url={URLS.patients}
                        columns={columns}
                        hasDeleteColumn={false}
                        hasEditColumn={hasPermission(get(user, 'permissions', []), [config.PERMISSIONS.patient_edit])}
                    />
                </div>
            </div>
        </div>
    );
};

export default PatientsContainer;
