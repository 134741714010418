import React from 'react';
import Dropdown from "../dropdown";
import bellIcon from "../../assets/icons/bell.svg";
import {get} from "lodash";
import config from "../../config";
import {useGetAllQuery} from "../../hooks/api";
import {KEYS} from "../../constants/keys";
import {URLS} from "../../constants/urls";

const Index = () => {
    const {data: notifications, isLoading} = useGetAllQuery({
        key: KEYS.notifications,
        url: URLS.notifications,
        refetchInterval: 5000,
        hideErrorMsg: true
    })
    return (
        <>
            <div className={'cursor-pointer mr-8'}>
                <Dropdown btn={<div className="cursor-pointer flex items-center relative z-50">
                    <img src={bellIcon} alt=""/>
                    {get(notifications, 'data.payload.data', [])?.length > 0 && <span
                        className={'absolute inline-flex justify-center items-center bg-red-400 w-[18px] h-[18px]  rounded-full text-[8px] text-white  overflow-hidden -right-1/2 -top-1/3'}>{get(notifications, 'data.payload.data', [])?.length}</span>}
                </div>}>
                    <ul className={'w-[225px] bg-white absolute  rounded-b-xl p-2.5 z-50 max-h-[50vh] overflow-y-auto'}>
                        {
                            get(notifications, 'data.payload.data', []).map((item, i) => <li
                                className={'cursor-pointer p-2 font-semibold flex items-center'}>
                                <a className={'text-blue-400'}
                                   href={`${config.FILE_URL}${get(item, 'data.download_link')}`}>{i + 1}.{get(item, 'title')}</a>
                            </li>)
                        }

                    </ul>
                </Dropdown>

            </div>
        </>
    );
};

export default Index;