import React, {useState} from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ChevronLeft, Edit2, Eye, Trash2} from "react-feather";
import Content from "../../../components/content";
import {KEYS} from "../../../constants/keys";
import {useGetAllQuery, useGetOneQuery, usePostQuery, usePutQuery} from "../../../hooks/api";
import {URLS} from "../../../constants/urls";
import {OverlayLoader} from "../../../components/loader";
import {find, get, head, includes, isEqual, isNil, omit} from "lodash";
import Form from "../../../containers/form";
import Field from "../../../containers/form/field";
import GridView from "../../../containers/grid-view";
import {FIELDS, REPORT_TYPE_STATUS} from "../../../constants";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import {Tab, Tabs} from "../../../components/tab";
import Button from "../../../components/button";
import clsx from "clsx";
import {AllowedAccess} from "react-permission-role";
import config from "../../../config";
import Badge from "../../../components/badge";
import {toast} from "react-toastify";
import {IconButton, Popover, ButtonGroup, Button as RButton, Whisper, Dropdown} from "rsuite";
import {ArrowDown} from "@rsuite/icons";
import Modal from "../../../components/modal";
import RecipeDetailModal from "../../../components/recipe-detail-modal";

const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

const PatientRecipeContainer = ({id}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [row, setRow] = useState(null)
    const [detail, setDetail] = useState(null)
    const [ratio_to_daily, set_ratio_to_daily] = useState(1)
    const [day_count, set_day_count] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [vproduct, setVproduct] = useState(null)
    const {data, isLoading} = useGetOneQuery({id: id, url: URLS.patients, key: [KEYS.patients, id]})
    const {data: encounter, isLoading: isLoadingEncounter, refetch} = useGetAllQuery({
        key: KEYS.encounters,
        disableParams: true,
        url: `${URLS.encounters}?patient_id=${id}`
    })

    const {
        mutate: createMedication, isLoading: isLoadingPost
    } = usePostQuery({listKeyId: `${KEYS.medicationRequest}-today`})
    const {
        mutate: updateMedication, isLoading: isLoadingPut
    } = usePutQuery({listKeyId: `${KEYS.medicationRequest}-today`})
    const createMedicationRequest = ({data: attrs, reset}) => {
        createMedication({
                url: URLS.medicationRequest,
                attributes: {
                    ...omit(attrs, ['instructions[0].dosage_unit', 'instructions']),
                    instructions: [{
                        ...get(attrs, 'instructions[0]'),
                        dosage_unit: get(attrs, 'instructions[0].dosage_unit')
                    }],
                    patient: {id: id},
                    type: {id: 1}
                }
            },
            {
                onSuccess: () => {
                    reset({
                        'instructions[0].patient_instruction': '',
                        'instructions[0].route': '',
                        'instructions[0].quantity': '',
                        'instructions[0].timing.frequency': '',
                        'instructions[0].timing.days_count': '',
                        'instructions[0].dosage_unit': '',
                        'instructions[0].per_dose_qty': '',
                        'medication.qty_unit': '',
                        'medication.vproduct': ''
                    })
                },
            })
    }
    const updateMedicationRequest = ({data: attrs}) => {
        updateMedication({
                url: `${URLS.medicationRequest}/${get(row, 'id')}`,
                attributes: {...attrs, patient: {id: id}, type: {id: 1}}
            },
            {
                onSuccess: () => {
                    setRow(null);
                }
            })
    }
    const {
        mutate: reviewRequest,
        isLoading: isLoadingReview
    } = usePutQuery({listKeyId: `${KEYS.medicationRequest}-today`})
    const reviewMedicationRequest = (_id) => {
        Swal.fire({
            position: 'center',
            icon: 'info',
            title: t('Are you sure?'),
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3F6A83',
            confirmButtonText: t('Отправить'),
            cancelButtonText: t('Отмена'),
        }).then((result) => {
            if (result.isConfirmed) {
                reviewRequest({url: `${URLS.medicationRequest}/${_id}/in-review`})
            }
        });
    }
    const cancel = (_id) => {
        reviewRequest({url: `${URLS.medicationRequest}/${_id}/cancelled`})
    }
    const enteredError = (_id) => {
        reviewRequest({url: `${URLS.medicationRequest}/${_id}/entered-in-error`})
    }
    const {
        mutate: finishEncounterRequest,
        isLoading: isLoadingFinish
    } = usePutQuery({listKeyId: [KEYS.encounters, `${KEYS.medicationRequest}-today`]})

    const closeAndSendForConfirmation = (groupId) => {
        if (groupId) {
            finishEncounterRequest({
                url: `/medication-requests/group/${groupId}/send-for-confirmation`
            }, {
                onSuccess: () => {
                    refetch()
                }
            })
        } else {
            toast.warn(t('No medication request in this session'))
        }
    }
    const getActiveEncounterId = () => {
        return get(find(get(encounter, 'data.payload.data', []), item => isEqual(get(item, 'status.code'), 'in-progress')), 'id') || null
    }
    const {data: requests} = useGetAllQuery({
        key: KEYS.medicationRequest,
        disableParams: true,
        url: `${URLS.medicationRequest}`,
        params: {
            params: {
                encounter_id: getActiveEncounterId(),
                sort: '-created_at'
            }
        },
        enabled: !!(getActiveEncounterId())
    })

    if (isLoading || isLoadingEncounter) {
        return <OverlayLoader/>
    }

    return (<>
            {(isLoadingPost || isLoadingReview || isLoadingPut) && <OverlayLoader/>}
            <div className="grid grid-cols-12">
                <div className="col-span-12 mb-5">
                    <button onClick={() => navigate(-1)} className={'text-primary  font-bold inline-flex items-center'}
                    ><ChevronLeft className={'mr-1'}/>{t("Назад к пациент")}</button>
                </div>
                <div className="col-span-12">
                    <Title className={'mb-3'}>{t("Рецепт")} <span
                        className={'ml-6 text-2xl'}>( {get(data, 'data.payload.patient.last_name')} {get(data, 'data.payload.patient.first_name')} {get(data, 'data.payload.patient.middle_name')} )</span></Title>
                </div>
                <div className="col-span-12">
                    <Content sm>
                        <div className="grid grid-cols-12 gap-x-6">
                            <div
                                className="col-span-12 p-6 rounded-xl shadow-xl drop-shadow-xl border-[3px] border-[rgba(0,0,0,0.1)] mb-10">
                                <Title sm>{t("Выписать рецепт")}</Title>
                                {!isNil(row) && <Form formName={'update-form'} defaultValues={{...row}}
                                                      classNames={'grid grid-cols-12 gap-x-6 mt-4'}
                                                      formRequest={updateMedicationRequest}
                                                      footer={<div className={'col-span-12'}>
                                                          <div className="flex justify-end">
                                                              <button type={'button'}
                                                                      onClick={() => setRow(null)}
                                                                      className={' py-3 px-6 rounded-lg bg-red-500 inline-block  font-bold text-center  mt-6 mr-3 text-white'}>
                                                                  {t('Cancel')}
                                                              </button>
                                                              <button type={'submit'}
                                                                      className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                                                  {t('Save')}
                                                              </button>
                                                          </div>
                                                      </div>}>

                                    <Field
                                        dataKey={'payload.data'}
                                        type={FIELDS.asyncSelect}
                                        url={URLS.medicationVirtualProducts}
                                        keyId={KEYS.medicationVirtualProducts}
                                        classNames={'col-span-3'}
                                        name={'medication.vproduct'}
                                        params={{
                                            required: true,
                                        }}
                                        label={t('vproduct')}
                                    />
                                    <Field
                                        type={FIELDS.input}
                                        classNames={'col-span-3'}
                                        name={'instructions[0].per_dose_qty'}
                                        params={{
                                            required: true,
                                        }}
                                        property={{type: 'number', onChange: (val) => setQuantity(val)}}
                                        label={t('Разовая доза')}
                                    />
                                    <Field
                                        type={FIELDS.asyncSelect}
                                        url={URLS.medicationDosageUnit}
                                        keyId={KEYS.medicationDosageUnit}
                                        classNames={'col-span-3'}
                                        name={'instructions[0].dosage_unit'}
                                        params={{
                                            required: true,
                                        }}
                                        label={t('dosage_unit')}
                                    />
                                    <Field property={{type: 'number', onChange: (val) => set_day_count(val)}}
                                           params={{
                                               required: true,
                                           }} label={t('days_count')} type={FIELDS.input}
                                           classNames={'col-span-3'}
                                           name={`instructions[0].timing.days_count`}
                                    />
                                    <Field
                                        type={FIELDS.asyncSelect}
                                        url={URLS.timingAbbreviation}
                                        keyId={KEYS.timingAbbreviation}
                                        classNames={'col-span-3'}
                                        name={'instructions[0].timing.frequency'}
                                        params={{
                                            required: true,
                                        }}
                                        property={{
                                            onChange: (val) => set_ratio_to_daily(get(val, 'ratio_to_daily'))
                                        }}
                                        label={t('timing.frequency')}
                                    />

                                    <Field
                                        defaultValue={day_count * ratio_to_daily * quantity}
                                        type={FIELDS.input}
                                        classNames={'col-span-3'}
                                        name={'instructions[0].quantity'}
                                        params={{
                                            required: true,
                                        }}
                                        property={{type: 'number'}}
                                        label={t('quantity')}
                                    />
                                    <Field
                                        type={FIELDS.asyncSelect}
                                        url={URLS.routeCode}
                                        keyId={KEYS.routeCode}
                                        classNames={'col-span-3'}
                                        name={'instructions[0].route'}
                                        params={{
                                            required: true,
                                        }}
                                        label={t('route')}
                                    />
                                    <Field
                                        dateFormat={"yyyy-MM-dd"}
                                        type={FIELDS.datepicker}
                                        defaultValue={dayjs().add(7, 'days')}
                                        classNames={'col-span-3'}
                                        name={'valid_until'}
                                        params={{
                                            required: true,
                                        }}
                                        label={t('valid_until')}
                                    />
                                    <Field
                                        type={FIELDS.textarea}
                                        classNames={'col-span-12'}
                                        name={'instructions[0].patient_instruction'}
                                        label={t('patient_instruction')}
                                    />
                                </Form>}
                                {
                                    isNil(row) &&
                                    <Form formName={'create-form'} classNames={'grid grid-cols-12 gap-x-6 mt-4'}
                                          formRequest={createMedicationRequest}
                                          footer={<div className={'col-span-12 '}>
                                              <div className="flex justify-end">
                                                  <button disabled={get(requests, 'data.payload.data', [])?.length >= 3}
                                                          type={'submit'}
                                                          className={' py-3 px-6 rounded-lg bg-primary inline-block  text-white font-bold text-center  mt-6'}>
                                                      {t('Добавить')}
                                                  </button>
                                              </div>
                                          </div>}>
                                        <Field
                                            dataKey={'payload.data'}
                                            type={FIELDS.asyncSelect}
                                            url={URLS.medicationVirtualProducts}
                                            keyId={KEYS.medicationVirtualProducts}
                                            classNames={'col-span-3'}
                                            name={'medication.vproduct'}
                                            params={{
                                                required: true,
                                            }}
                                            property={{
                                                onChange: (val) => {
                                                    setVproduct(val)
                                                }
                                            }}
                                            label={t('vproduct')}
                                        />
                                        <Field
                                            type={FIELDS.input}
                                            classNames={'col-span-3'}
                                            name={'instructions[0].per_dose_qty'}
                                            params={{
                                                required: true,
                                            }}
                                            property={{type: 'number', onChange: (val) => setQuantity(val)}}
                                            label={t('Разовая доза')}
                                        />
                                        <Field
                                            type={FIELDS.asyncSelect}
                                            url={URLS.medicationDosageUnit}
                                            keyId={KEYS.medicationDosageUnit}
                                            classNames={'col-span-3'}
                                            name={'instructions[0].dosage_unit'}
                                            params={{
                                                required: true,
                                            }}
                                            property={{defaultSearchAttrName: "code"}}
                                            defaultValue={get(vproduct, 'dosage_form.code')}
                                            label={t('dosage_unit')}
                                        />
                                        <Field property={{type: 'number', onChange: (val) => set_day_count(val)}}
                                               params={{
                                                   required: true,
                                               }} label={t('days_count')} type={FIELDS.input}
                                               classNames={'col-span-3'}
                                               name={`instructions[0].timing.days_count`}
                                        />
                                        <Field
                                            type={FIELDS.asyncSelect}
                                            url={URLS.timingAbbreviation}
                                            keyId={KEYS.timingAbbreviation}
                                            classNames={'col-span-3'}
                                            name={'instructions[0].timing.frequency'}
                                            params={{
                                                required: true,
                                            }}
                                            property={{
                                                onChange: (val) => set_ratio_to_daily(get(val, 'ratio_to_daily'))
                                            }}
                                            label={t('timing.frequency')}
                                        />

                                        <Field
                                            defaultValue={day_count * ratio_to_daily * quantity}
                                            type={FIELDS.input}
                                            classNames={'col-span-3'}
                                            name={'instructions[0].quantity'}
                                            params={{
                                                required: true,
                                            }}
                                            property={{type: 'number'}}
                                            label={t('quantity')}
                                        />
                                        <Field
                                            type={FIELDS.asyncSelect}
                                            url={URLS.routeCode}
                                            keyId={KEYS.routeCode}
                                            classNames={'col-span-3'}
                                            name={'instructions[0].route'}
                                            params={{
                                                required: true,
                                            }}
                                            label={t('route')}
                                        />
                                        <Field
                                            dateFormat={"yyyy-MM-dd"}
                                            type={FIELDS.datepicker}
                                            defaultValue={dayjs().add(7, 'days')}
                                            classNames={'col-span-3'}
                                            name={'valid_until'}
                                            params={{
                                                required: true,
                                            }}
                                            label={t('valid_until')}
                                        />
                                        <Field
                                            type={FIELDS.textarea}
                                            classNames={'col-span-12'}
                                            name={'instructions[0].patient_instruction'}
                                            label={t('patient_instruction')}
                                        />

                                    </Form>
                                }
                            </div>
                            <div className="col-span-12">
                                {getActiveEncounterId() && !includes(['all', 'today'], searchParams.get('tab')) &&
                                <div className="text-right">
                                    <Button
                                        loading={isLoadingFinish}
                                        onClick={() => closeAndSendForConfirmation(get(head(get(requests, 'data.payload.data', [])), 'group_id'))}
                                        classNames={'bg-red-500 border-none text-white min-w-48 text-center  justify-center'}>
                                        {t("Close and send for confirmation")}
                                    </Button>
                                </div>}
                                <div
                                    className={clsx({'-mt-11': getActiveEncounterId() && !includes(['all', 'today'], searchParams.get('tab'))})}>
                                    <Tabs>
                                        <Tab tab={'active'} label={t('Выписанное лекарство в одном сессии')}>
                                            {getActiveEncounterId() ? <GridView
                                                bordered
                                                dataKey={'data.payload.data'}
                                                metaDataKey={'data.payload.meta'}
                                                params={{
                                                    encounter_id: getActiveEncounterId(),
                                                    sort: '-updated_at'
                                                }}
                                                listKey={`${KEYS.medicationRequest}-today`}
                                                url={URLS.medicationRequest}
                                                columns={[
                                                    {
                                                        title: t('№ рецепт'),
                                                        key: 'rx_code',
                                                        render: ({value, row: _row}) => <span>{value}</span>
                                                    },
                                                    {
                                                        title: t('Наименование'),
                                                        key: 'medication.vproduct.display',
                                                    },
                                                    {
                                                        title: t('Разовая доза'),
                                                        key: 'medication.vproduct.active_ingredient_qty',
                                                        render: ({
                                                                     row
                                                                 }) => ` ${get(row, 'instructions[0].per_dose_qty')} ${get(row, 'instructions[0].dosage_unit.display')}`
                                                    },

                                                    {
                                                        title: t('Обшее количество ЛС'),
                                                        key: 'instructions[0].quantity',
                                                        render: ({
                                                                     value,
                                                                     row
                                                                 }) => <>{get(row, 'instructions[0].timing.frequency.display')}
                                                            <strong
                                                                className={'mx-1'}>*</strong> {get(row, 'instructions[0].timing.days_count')}
                                                            <strong
                                                                className={'mx-1'}>*</strong> {get(row, 'instructions[0].per_dose_qty')} = {value}</>
                                                    },
                                                    {
                                                        title: t('Способ введения'),
                                                        key: 'instructions[0].route.display',
                                                    },
                                                    {
                                                        title: t('Примечание'),
                                                        key: 'instructions[0].patient_instruction',
                                                        checkIsVisible: true
                                                    },
                                                    {
                                                        title: t('Created at'),
                                                        key: 'created_at',
                                                        render: ({value}) => dayjs(value).format("DD.MM.YYYY HH:mm")
                                                    },
                                                    {
                                                        title: t('Status'),
                                                        key: 'status.code',
                                                        render: ({
                                                                     value,
                                                                     row: _row
                                                                 }) => <Badge
                                                            status={value}>{get(_row, 'status.display')}</Badge>
                                                    },
                                                    {
                                                        title: t('Actions'),
                                                        key: 'status.code',
                                                        render: ({
                                                                     value,
                                                                     row: _row
                                                                 }) => isEqual(value, REPORT_TYPE_STATUS.draft) ?
                                                            <div className={'flex justify-center items-center'}>
                                                                <Eye onClick={() => setDetail(_row)} color={'#71C45C'}
                                                                     className={'mr-3 flex-none'} size={21}/>
                                                                <AllowedAccess
                                                                    permissions={[config.PERMISSIONS.medication_request_edit]}>
                                                                    <div
                                                                        className={'flex justify-center items-center '}>
                                                                        <Edit2 color={'#3F6A83'}
                                                                               className={'mr-4 flex-none'} size={20}
                                                                               onClick={() => {
                                                                                   setRow(null);
                                                                                   setRow(_row)
                                                                               }}/>
                                                                        <ButtonGroup className={'flex'}>
                                                                            <RButton appearance="default"> <Trash2
                                                                                className={'text-red-500 '} size={20}/></RButton>
                                                                            <Whisper
                                                                                placement="bottomEnd"
                                                                                trigger="click"
                                                                                speaker={({
                                                                                              onClose,
                                                                                              left,
                                                                                              top,
                                                                                              className
                                                                                          }, ref) => {
                                                                                    return (
                                                                                        <Popover ref={ref}
                                                                                                 className={className}
                                                                                                 style={{left, top}}
                                                                                                 full>
                                                                                            <Dropdown.Menu>
                                                                                                <Dropdown.Item
                                                                                                    onClick={() => enteredError(get(_row, 'id'))}>
                                                                                                    {t('Mark as entered-in-error')}
                                                                                                </Dropdown.Item>
                                                                                                <Dropdown.Item
                                                                                                    onClick={() => cancel(get(_row, 'id'))}>
                                                                                                    {t('Cancel the drug')}
                                                                                                </Dropdown.Item>
                                                                                            </Dropdown.Menu>
                                                                                        </Popover>
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <IconButton appearance="default"
                                                                                            icon={<ArrowDown/>}/>
                                                                            </Whisper>
                                                                        </ButtonGroup>
                                                                    </div>
                                                                </AllowedAccess>
                                                            </div> :
                                                            ""
                                                    },
                                                ]}

                                            /> : <p className={'text-center'}>{t("No data")}</p>}
                                        </Tab>
                                        <Tab tab={'today'} label={t('Сегодняшние рецепты')}>
                                            <GridView
                                                bordered
                                                dataKey={'data.payload.data'}
                                                metaDataKey={'data.payload.meta'}
                                                params={{
                                                    date_from: dayjs().format("YYYY-MM-DD"),
                                                    date_to: dayjs().add(1, 'day').format("YYYY-MM-DD"),
                                                    patient_id: id,
                                                    sort: '-updated_at'
                                                }}
                                                listKey={`${KEYS.medicationRequest}-today`}
                                                url={URLS.medicationRequest}
                                                columns={[
                                                    {
                                                        title: t('№ рецепт'),
                                                        key: 'rx_code',
                                                        render: ({value, row: _row}) => <span>{value}</span>
                                                    },
                                                    {
                                                        title: t('Наименование'),
                                                        key: 'medication.vproduct.display',
                                                    },
                                                    {
                                                        title: t('Разовая доза'),
                                                        key: 'medication.vproduct.active_ingredient_qty',
                                                        render: ({
                                                                     row
                                                                 }) => ` ${get(row, 'instructions[0].per_dose_qty')} ${get(row, 'instructions[0].dosage_unit.display')}`
                                                    },

                                                    {
                                                        title: t('Обшее количество ЛС'),
                                                        key: 'instructions[0].quantity',
                                                        render: ({
                                                                     value,
                                                                     row
                                                                 }) => <>{get(row, 'instructions[0].timing.frequency.display')}
                                                            <strong
                                                                className={'mx-1'}>*</strong> {get(row, 'instructions[0].timing.days_count')}
                                                            <strong
                                                                className={'mx-1'}>*</strong> {get(row, 'instructions[0].per_dose_qty')} = {value}</>
                                                    },
                                                    {
                                                        title: t('Способ введения'),
                                                        key: 'instructions[0].route.display',
                                                    },
                                                    {
                                                        title: t('Примечание'),
                                                        key: 'instructions[0].patient_instruction',
                                                        checkIsVisible: true
                                                    },
                                                    {
                                                        title: t('Created at'),
                                                        key: 'created_at',
                                                        render: ({value}) => dayjs(value).format("DD.MM.YYYY HH:mm")
                                                    },
                                                    {
                                                        title: t('Status'),
                                                        key: 'status.code',
                                                        render: ({
                                                                     value,
                                                                     row
                                                                 }) => <>
                                                            <Badge
                                                                status={value}>{get(row, 'status.display')}</Badge>
                                                            {get(row, 'status.reason_text') && <div
                                                                className={'text-center mt-2'}>{get(row, 'status.reason_text')}</div>}
                                                        </>
                                                    },
                                                    {
                                                        title: t('Actions'),
                                                        key: 'status.code',
                                                        classnames: 'min-w-[150px]',
                                                        render: ({
                                                                     value,
                                                                     row: _row
                                                                 }) => <div
                                                            className={'flex items-center justify-center'}>
                                                            <Eye onClick={() => setDetail(_row)} color={'#71C45C'}
                                                                 className={'mr-3 flex-none'} size={21}/>
                                                            {isEqual(value, REPORT_TYPE_STATUS.draft) ?
                                                                <>
                                                                    <AllowedAccess
                                                                        permissions={[config.PERMISSIONS.medication_request_edit]}>
                                                                        <div
                                                                            className={'flex justify-center items-center'}>
                                                                            <Edit2 color={'#3F6A83'}
                                                                                   className={'mr-4 flex-none'}
                                                                                   size={20}
                                                                                   onClick={() => {
                                                                                       setRow(null);
                                                                                       setRow(_row)
                                                                                   }}/>
                                                                            <ButtonGroup className={'flex'}>
                                                                                <RButton appearance="default"> <Trash2
                                                                                    className={'text-red-500 '}
                                                                                    size={20}/></RButton>
                                                                                <Whisper
                                                                                    placement="bottomEnd"
                                                                                    trigger="click"
                                                                                    speaker={({
                                                                                                  onClose,
                                                                                                  left,
                                                                                                  top,
                                                                                                  className
                                                                                              }, ref) => {
                                                                                        return (
                                                                                            <Popover ref={ref}
                                                                                                     className={className}
                                                                                                     style={{left, top}}
                                                                                                     full>
                                                                                                <Dropdown.Menu>
                                                                                                    <Dropdown.Item
                                                                                                        onClick={() => enteredError(get(_row, 'id'))}>
                                                                                                        {t('Mark as entered-in-error')}
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item
                                                                                                        onClick={() => cancel(get(_row, 'id'))}>
                                                                                                        {t('Cancel the drug')}
                                                                                                    </Dropdown.Item>
                                                                                                </Dropdown.Menu>
                                                                                            </Popover>
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <IconButton appearance="default"
                                                                                                icon={<ArrowDown/>}/>
                                                                                </Whisper>
                                                                            </ButtonGroup>
                                                                        </div>
                                                                    </AllowedAccess>
                                                                </> : isEqual(value, REPORT_TYPE_STATUS.rejected) ? <>
                                                                        <AllowedAccess
                                                                            permissions={[config.PERMISSIONS.medication_request_edit]}>
                                                                            <div
                                                                                className={'flex justify-center items-center'}>
                                                                                <Edit2 color={'#3F6A83'}
                                                                                       className={'mr-4 flex-none'}
                                                                                       size={20}
                                                                                       onClick={() => {
                                                                                           setRow(null);
                                                                                           setRow(_row)
                                                                                       }}/>
                                                                                <ButtonGroup className={'flex mr-3'}>
                                                                                    <RButton appearance="default"> <Trash2
                                                                                        className={'text-red-500 '}
                                                                                        size={20}/></RButton>
                                                                                    <Whisper
                                                                                        placement="bottomEnd"
                                                                                        trigger="click"
                                                                                        speaker={({
                                                                                                      onClose,
                                                                                                      left,
                                                                                                      top,
                                                                                                      className
                                                                                                  }, ref) => {
                                                                                            return (
                                                                                                <Popover ref={ref}
                                                                                                         className={className}
                                                                                                         style={{left, top}}
                                                                                                         full>
                                                                                                    <Dropdown.Menu>
                                                                                                        <Dropdown.Item
                                                                                                            onClick={() => enteredError(get(_row, 'id'))}>
                                                                                                            {t('Mark as entered-in-error')}
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item
                                                                                                            onClick={() => cancel(get(_row, 'id'))}>
                                                                                                            {t('Cancel the drug')}
                                                                                                        </Dropdown.Item>
                                                                                                    </Dropdown.Menu>
                                                                                                </Popover>
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <IconButton appearance="default"
                                                                                                    icon={<ArrowDown/>}/>
                                                                                    </Whisper>
                                                                                </ButtonGroup>
                                                                            </div>
                                                                        </AllowedAccess><AllowedAccess
                                                                        permissions={[config.PERMISSIONS.medication_request_update_status]}>
                                                                        <button
                                                                            type={"button"}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                reviewMedicationRequest(get(_row, 'id'));
                                                                            }}
                                                                            className={' py-2.5 px-5  text-white min-w-[150px] rounded-lg  border bg-[#006D85] font-bold text-center   items-center '}>
                                                                            {t("Send")}
                                                                        </button>
                                                                    </AllowedAccess></> :
                                                                    ""}
                                                        </div>
                                                    },
                                                ]}

                                            />
                                        </Tab>
                                        <Tab tab={'all'} label={t('Ранее выписанные рецепты')}>
                                            {id && <GridView
                                                bordered
                                                dataKey={'data.payload.data'}
                                                metaDataKey={'data.payload.meta'}
                                                params={{
                                                    patient_id: id,
                                                    sort: '-updated_at'
                                                }}
                                                listKey={[KEYS.medicationRequest, 'all']}
                                                url={URLS.medicationRequest}
                                                columns={[
                                                    {
                                                        title: t('№ рецепт'),
                                                        key: 'rx_code',
                                                        render: ({value, row: _row}) => <span
                                                            className={'min-w-[125px] block'}>{value}</span>
                                                    },
                                                    {
                                                        title: t('Наименование'),
                                                        key: 'medication.vproduct.display',
                                                    },
                                                    {
                                                        title: t('Разовая доза'),
                                                        key: 'medication.vproduct.active_ingredient_qty',
                                                        render: ({
                                                                     row
                                                                 }) => ` ${get(row, 'instructions[0].per_dose_qty')} ${get(row, 'instructions[0].dosage_unit.display')}`
                                                    },

                                                    {
                                                        title: t('Обшее количество ЛС'),
                                                        key: 'instructions[0].quantity',
                                                        render: ({
                                                                     value,
                                                                     row
                                                                 }) => <>{get(row, 'instructions[0].timing.frequency.display')}
                                                            <strong
                                                                className={'mx-1'}>*</strong> {get(row, 'instructions[0].timing.days_count')}
                                                            <strong
                                                                className={'mx-1'}>*</strong> {get(row, 'instructions[0].per_dose_qty')} = {value}</>
                                                    },
                                                    {
                                                        title: t('Способ введения'),
                                                        key: 'instructions[0].route.display',
                                                    },
                                                    {
                                                        title: t('Примечание'),
                                                        key: 'instructions[0].patient_instruction',
                                                        checkIsVisible: true
                                                    },
                                                    {
                                                        title: t('Created at'),
                                                        key: 'created_at',
                                                        render: ({value}) => dayjs(value).format("DD.MM.YYYY HH:mm")
                                                    },
                                                    {
                                                        title: t('Status'),
                                                        key: 'status.code',
                                                        render: ({
                                                                     value,
                                                                     row
                                                                 }) => <Badge
                                                            status={value}>{get(row, 'status.display')}</Badge>

                                                    },
                                                    {
                                                        title: t('Actions'),
                                                        key: 'status.code',
                                                        render: ({
                                                                     row: _row
                                                                 }) => <Eye onClick={() => setDetail(_row)}
                                                                            color={'#71C45C'}
                                                                            className={'mx-auto flex-none'} size={21}/>
                                                    }
                                                ]}
                                            />}
                                        </Tab>
                                    </Tabs>
                                </div>

                            </div>
                        </div>
                    </Content>
                </div>
            </div>
            <Modal open={!isNil(detail)} onClose={() => setDetail(null)} classNames={'!w-[1000px]'}
                   title={t('Подробности рецепта')}>
                <RecipeDetailModal row={detail}/>
            </Modal>
        </>
    );
}

export default PatientRecipeContainer;
